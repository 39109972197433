
.popinfo {
	color: #88c8f7;
}

.popsuccess {
	color: #bfff80;
}

.popdanger {
	color: #ea6262;
}

.popwarning {
	color: #f7db6e;
}

.popover {
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: .875rem;
	word-wrap: break-word;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid #dee3eb;
	border-radius: 3px;
	-webkit-filter: drop-shadow(0 1px 3px $black-1);
	filter: drop-shadow(0 1px 3px $black-1);
	.arrow {
		position: absolute;
		display: block;
		width: .5rem;
		height: .5rem;
		margin: 0 3px;
		margin-left: calc(.25rem + -5px);
		&::after,
		&::before {
			position: absolute;
			display: block;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}

.bs-popover-auto[data-popper-placement^=top],
.bs-popover-top {
	margin-bottom: .5rem;
}

.bs-popover-auto[data-popper-placement^=top] .arrow,
.bs-popover-top .arrow {
	bottom: calc((.5rem + 1px) * -1);
}

.bs-popover-auto[data-popper-placement^=top] .arrow {
	&::after,
	&::before {
		border-width: .5rem .25rem 0;
	}
}

.bs-popover-top .arrow {
	&::after,
	&::before {
		border-width: .5rem .25rem 0;
	}
}

.bs-popover-auto[data-popper-placement^=top] .arrow::before,
.bs-popover-top .arrow::before {
	bottom: 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^=top] .arrow::after,
.bs-popover-top .arrow::after {
	bottom: 1px;
	border-top-color: $white;
}

.bs-popover-auto[data-popper-placement^=right],
.bs-popover-end {
	margin-left: .5rem;
}

.bs-popover-auto[data-popper-placement^=right] .arrow,
.bs-popover-end .arrow {
	left: calc((.5rem + 1px) * -1);
	width: .5rem;
	height: .5rem;
	margin: 3px 0;
}

.bs-popover-auto[data-popper-placement^=right] .arrow {
	&::after,
	&::before {
		border-width: .25rem .5rem .25rem 0;
	}
}

.bs-popover-end .arrow {
	&::after,
	&::before {
		border-width: .25rem .5rem .25rem 0;
	}
}

.bs-popover-auto[data-popper-placement^=right] .arrow::before,
.bs-popover-end .arrow::before {
	left: 0;
	border-right-color: #dee3eb;
}

.bs-popover-auto[data-popper-placement^=right] .arrow::after,
.bs-popover-end .arrow::after {
	left: 1px;
	border-right-color: $white;
}

.bs-popover-auto[data-popper-placement^=bottom],
.bs-popover-bottom {
	margin-top: .5rem;
}

.bs-popover-auto[data-popper-placement^=bottom] .arrow,
.bs-popover-bottom .arrow {
	top: calc((.5rem + 1px) * -1);
}

.bs-popover-auto[data-popper-placement^=bottom] .arrow {
	&::after,
	&::before {
		border-width: 0 .25rem .5rem .25rem;
	}
}

.bs-popover-bottom .arrow {
	&::after,
	&::before {
		border-width: 0 .25rem .5rem .25rem;
	}
}

.bs-popover-auto[data-popper-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::before {
	top: 0;
	border-bottom-color: #dee3eb;
}

.bs-popover-auto[data-popper-placement^=bottom] .arrow::after,
.bs-popover-bottom .arrow::after {
	top: 1px;
	border-bottom-color: $white;
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: .5rem;
	margin-left: -.25rem;
	content: "";
	border-bottom: 1px solid $white;
}

.bs-popover-auto[data-popper-placement^=left],
.bs-popover-start {
	margin-right: .5rem;
}

.bs-popover-auto[data-popper-placement^=left] .arrow,
.bs-popover-start .arrow {
	right: calc((.5rem + 1px) * -1);
	width: .5rem;
	height: .5rem;
	margin: 3px 0;
}

.bs-popover-auto[data-popper-placement^=left] .arrow {
	&::after,
	&::before {
		border-width: .25rem 0 .25rem .5rem;
	}
}

.bs-popover-start .arrow {
	&::after,
	&::before {
		border-width: .25rem 0 .25rem .5rem;
	}
}

.bs-popover-auto[data-popper-placement^=left] .arrow::before,
.bs-popover-start .arrow::before {
	right: 0;
	border-left-color: #dee3eb;
}

.bs-popover-auto[data-popper-placement^=left] .arrow::after,
.bs-popover-start .arrow::after {
	right: 1px;
	border-left-color: $white;
}

.popover-header {
	padding: .5rem .75rem;
	margin-bottom: 0;
	font-size: .9375rem;
	color: inherit;
	background-color: $white;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: calc(3px - 1px);
	border-top-right-radius: calc(3px - 1px);
	&:empty {
		display: none;
	}
}

.popover-body {
	padding: .75rem 1rem;
	color: $color2;
}

.popover {
	&.bs-popover-auto[data-popper-placement^=top],
	&.bs-popover-top {
		margin-bottom: .625rem;
	}
}

.popover-static-demo .popover {
	.arrow {
		margin-left: calc(.25rem + 122px);
	}
	position: relative;
}